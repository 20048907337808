import App from './App'
import OtaApp from './OtaApp'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import VueRouter from 'vue-router'
import router from 'src/routes'
import store from 'store'
import 'url-search-params-polyfill'
import 'url-polyfill'
import 'normalize.css'
import 'intersection-observer'
import {
  ModalPlugin,
  LayoutPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  ButtonPlugin,
  AlertPlugin,
  CardPlugin,
  CarouselPlugin,
  CollapsePlugin,
  DropdownPlugin,
  ImagePlugin,
  LinkPlugin,
  ListGroupPlugin,
  PaginationPlugin,
  PopoverPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  TooltipPlugin,
  BreadcrumbPlugin,
  NavbarPlugin
} from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'
import VueMask from 'v-mask'
import 'stylesheets/bootstrap.scss'
import i18n from './i18n'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import { getInitAndStartApp } from 'utilities/init'
import 'filters/dateFilters'
import 'directives/visible'
import 'directives/theme'
import 'directives/loading'
import 'directives/modalBackground'
import 'errorHandler/init'
import VueMeta from 'vue-meta'
import VueCarousel from 'vue-carousel'
import PortalVue from 'portal-vue'
import { triplaPayRedirectPath } from 'src/helpers/triplaPay'
import VueRecognizer from 'vue-recognizer'
import FormError from 'components/FormError'
import Vue2TouchEvents from 'vue2-touch-events'
import TPagination from 'components/Common/TPagination'
import { appendReferrer } from 'utilities/referrerHelper'
import { isSSORedirect, containsEmail, cleanUpUrl } from 'src/utilities'
import { scrollToError } from 'utilities/domOperation'
import { isOta } from 'constants/tripla'
import VueSkeletonLoader from 'skeleton-loader-vue'
// import 'data-layer-helper/dist/data-layer-helper'

triplaPayRedirectPath()

const bootstrapSetting = {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
}
// https://github.com/popperjs/popper-core/issues/515
if (!SVGElement.prototype.contains) {
  SVGElement.prototype.contains = HTMLDivElement.prototype.contains
}

// eslint-disable-next-line prefer-const
let vm

Vue.prototype.$urlContainsEmail = containsEmail(location.href)
if (Vue.prototype.$urlContainsEmail && !location.href.includes('/reservation/confirmation')) {
  location.replace(cleanUpUrl(location.href))
}

Vue.use(VueRouter)
Vue.use(i18n)
// import bootstrap start
Vue.use(ModalPlugin, bootstrapSetting)
Vue.use(LayoutPlugin, bootstrapSetting)
Vue.use(FormPlugin, bootstrapSetting)
Vue.use(FormGroupPlugin, bootstrapSetting)
Vue.use(FormCheckboxPlugin, bootstrapSetting)
Vue.use(FormInputPlugin, bootstrapSetting)
Vue.use(FormRadioPlugin, bootstrapSetting)
Vue.use(FormSelectPlugin, bootstrapSetting)
Vue.use(FormTextareaPlugin, bootstrapSetting)
Vue.use(ButtonPlugin, bootstrapSetting)
Vue.use(AlertPlugin, bootstrapSetting)
Vue.use(CardPlugin, bootstrapSetting)
Vue.use(CarouselPlugin, bootstrapSetting)
Vue.use(CollapsePlugin, bootstrapSetting)
Vue.use(DropdownPlugin, bootstrapSetting)
Vue.use(ImagePlugin, bootstrapSetting)
Vue.use(LinkPlugin, bootstrapSetting)
Vue.use(ListGroupPlugin, bootstrapSetting)
Vue.use(PaginationPlugin, bootstrapSetting)
Vue.use(PopoverPlugin, bootstrapSetting)
Vue.use(SpinnerPlugin, bootstrapSetting)
Vue.use(TablePlugin, bootstrapSetting)
Vue.use(TabsPlugin, bootstrapSetting)
Vue.use(TooltipPlugin, bootstrapSetting)
Vue.use(Vue2TouchEvents)
// import bootstrap end
Vue.use(Vuelidate)
Vue.use(VTooltip)
Vue.use(VueMask)
Vue.use(VueCarousel)
Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_ATTR: ['target']
  }
})
Vue.use(VueMeta)
Vue.use(PortalVue)
Vue.use(VueRecognizer)

Vue.component('VueSkeletonLoader', VueSkeletonLoader)

if (!isOta) {
  Vue.use(VueAnalytics, {
    cookieFlags: '',
    fields: {
      // https://web.dev/samesite-cookies-explained/
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#cookieFlags
      cookieFlags: 'SameSite=None; Secure'
    },
    id: () => {
      if (isSSORedirect()) {
        return Promise.resolve()
      }

      return getInitAndStartApp(store, () => vm)
        .then((bookingWidgetSetting) => {
          if (vm.$urlContainsEmail) {
            vm.$logger.errorFromString('Email in URL detected!!!')
            return null
          }

          if (bookingWidgetSetting?.hotel_google_analytics_id) {
            return [
              bookingWidgetSetting.hotel_google_analytics_id,
              bookingWidgetSetting.tripla_google_analytics_id
            ]
          }
          return bookingWidgetSetting?.tripla_google_analytics_id || null
        })
        .catch((e) => {
          console.warn(e)
          return null
        })
    }
  })
} else {
  Vue.use(BreadcrumbPlugin, bootstrapSetting)
  Vue.use(NavbarPlugin)
}

Vue.prototype.$preview = location.href.includes('tripla_preview=true')
Vue.prototype.$ota = isOta
Vue.prototype.$clone = (item) => {
  return JSON.parse(JSON.stringify(item))
}
Vue.prototype.$tet = function (...args) {
  return this.$te(...args) && this.$t(...args)
}

/**
 * @param: error response from the API. can be either one of these four formats
 * { errors: [{title: 'string'}] }
 * { errors: { title: 'string' } }
 * { error: 'This is an error' }
 * { error: ['Error A', 'Error B''] }
 * @returns: an array that contains only the error messages
 * ['Error A' , 'Error B', 'string']
 */

Vue.prototype.$getFirstErrorInfo = (response) => {
  return response?.errors?.[0]?.details?.base?.[0] // { error, code }
}

Vue.prototype.$getErrorMessages = (response) => {
  let errors = []
  let error = []
  if (response?.errors) {
    errors = Array.isArray(response?.errors) ? response?.errors : [response?.errors]
  }
  if (response?.error) {
    error = Array.isArray(response?.error) ? response?.error : [response?.error]
  }
  return errors.length > 0 ? errors : error
}
Vue.prototype.$scrollToError = (...args) => vm.$nextTick(() => scrollToError(...args))
Vue.component('FormError', FormError)
Vue.component('TPagination', TPagination)

if (!isSSORedirect()) {
  const referrer_code = location.href.match(/[?&]referrer_code=[^?&#]+/gi)

  if (
    window.location.href.includes('#') &&
    /^#\//.test(window.location.hash) &&
    window.location.search
  ) {
    // handle hash route for GA events
    const path = location.hash.replace('#/', '')
    let query = location.search[0] === '?' ? location.search.substring(1) : location.search
    query = query[0] === '&' ? query.substring(1) : query
    if (
      path.includes('organization-sign-in') &&
      path.includes('organization_id') &&
      !referrer_code
    ) {
      const referrerQuery = appendReferrer()
      query = `${query}&${referrerQuery}`
    }
    const urlObject = new URL(`${location.origin}/${path}`)
    window.location = `${urlObject.href}${urlObject.search ? '&' : '?'}${query}`
  } else if (window.location.pathname === '/' && /^#\//.test(window.location.hash)) {
    // redirect hash route to history route
    if (
      location.href.includes('organization-sign-in') &&
      location.href.includes('organization_id') &&
      !referrer_code
    ) {
      const query = appendReferrer()
      window.location = `${location.href.replace('#/', '')}&${query}`
    } else {
      window.location = location.href.replace('#/', '')
    }
  } else {
    vm = new Vue({
      router,
      store,
      i18n,
      render: (h) => h(isOta ? OtaApp : App)
    })
    vm.$router.onReady(() => {
      vm.$mount('#app')
    })
  }
}

// for dataLayer debug
// function listener(model, message) {
//   console.log(model, message)
// }

// window.dataLayerHelper = new DataLayerHelper(window.dataLayer, listener)

// use this comment to get data model's value - data-layer-helper bug: https://github.com/google/data-layer-helper/issues/72
// window.google_tag_manager['GTM-P2ZSJ9M'].dataLayer.get('test_a')
