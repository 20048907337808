import { paymentMethod, ThirdPartyPayment } from 'constants/tripla'

const state = {
  selectedCurrency: '',
  temporaryCurrency: ''
}

const getters = {
  isMcpEnabled: (_, __, rootState, rootGetters) => {
    const whitelist = new Set((process.env.ENABLE_MCP_BRAND_IDS || '').split(','))
    const blacklist = new Set((process.env.DISABLE_MCP_BRAND_IDS || '').split(','))
    const brandId = String(rootGetters['setting/getBrandID'])

    const isFeatureFlagEnabled =
      whitelist.has(brandId) || (whitelist.has('all') && !blacklist.has(brandId))
    const isBrandEnabled = !!rootState.setting.init?.mcp_enabled

    return isFeatureFlagEnabled && isBrandEnabled
  },
  selectedCurrency: (state, _, __, rootGetters) => {
    return (
      state.temporaryCurrency || state.selectedCurrency || rootGetters['setting/getCurrency']?.code
    )
  },
  isMcpMode: (_, getters, rootState, rootGetters) => {
    const isOtherCurrencySelected =
      rootGetters['setting/getCurrency']?.code !== getters.selectedCurrency
    const isMcpReservation = rootGetters['booking/getReservationDetails']?.mcp_currency
    const isAdyen = rootState.setting.paymentGatewaySetting.name === ThirdPartyPayment.AdyenGmo
    return (getters.isMcpEnabled || isMcpReservation) && isOtherCurrencySelected && !isAdyen
  },
  temporaryCurrency: (state) => {
    return state.temporaryCurrency
  }
}

const mutations = {
  setSelectedCurrency: (state, payload) => {
    state.selectedCurrency = payload
  },
  togglePayment: (state, { method, isMcpAllowedCardBrand, originalCurrency }) => {
    const isAllowedMethod = method === paymentMethod.CreditCard && isMcpAllowedCardBrand
    state.temporaryCurrency = isAllowedMethod ? '' : originalCurrency
  },
  setTemporaryCurrency: (state, currency) => {
    state.temporaryCurrency = currency
  }
}

const actions = {
  setSelectedCurrency: ({ commit }, payload) => {
    commit('setSelectedCurrency', payload)
  },
  togglePayment: ({ commit, rootGetters }, { method, isMcpAllowedCardBrand }) => {
    commit('togglePayment', {
      method,
      isMcpAllowedCardBrand,
      originalCurrency: rootGetters['setting/getCurrency']?.code
    })
  },
  setTemporaryCurrency: ({ commit }, currency) => {
    commit('setTemporaryCurrency', currency)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
